<template>
  <div ref="offlineReminder" class="offline-remind">
    <el-table
      :data="tableData"
      stripe
      border
      style="width: 100%"
      :height="tableHeight"
    >
      <el-table-column type="index" label="序号" width="100"></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号码"
        :width="110 * $store.state.vehicle.screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="公司"
        :width="160 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="longTimeOffline"
        label="是否长时间离线"
        :width="160 * $store.state.vehicle.screenCoefficient"
      >
        <template slot-scope="scope">{{
          scope.row.longTimeOffline | filterOffline
        }}</template>
      </el-table-column>
      <el-table-column
        prop="gpsTime"
        label="离线时间"
        :width="180 * $store.state.vehicle.screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="location"
        label="位置"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <span
            style="cursor: pointer; color: #336ffe"
            @click="
              showAdsress(scope.row, scope.row.longitude, scope.row.latitude)
            "
            v-if="scope.row.location && scope.row.location.status"
            >查看地址</span
          >
          <span v-else>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="alarmType"
        label="报警类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ typeObj[scope.row.alarmType] }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="form.currentPage"
        :page-size="form.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getTablePlaceArr, formatDict,oneGpsToLngLat } from '@/common/utils/index'
import { getOfflineRemind } from '@/api/lib/refreshAlarm.js'
import { queryDictsByCodes } from '@/api/lib/api.js'
import {getGDPlace} from '@/common/utils/getPlace.js'
export default {
  data () {
    return {
      tableHeight: 0,
      form: {
        system: sessionStorage.getItem('system'),
        pageSize: 10,
        currentPage: 1
      },
      total: 0,
      tableData: [],
      typeObj: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vehOfflineReminder()
      this.computeHeight()
      this.getDicts()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  methods: {
    //查看地址
    showAdsress(row,lg,la){
      oneGpsToLngLat(lg,la).then((res) => {
        this.getLocation(res[0], res[1],row)
      })
    },
    /* 将经纬度转为地址信息 */
    getLocation (lng, lat,row) {
      let self = this
      getGDPlace(lng, lat, false).then((res) => {
        if (res) { 
          row.location = res;
        }
      })
    },
    getDicts () {
      queryDictsByCodes({ parentCodes: 'D0010,D0008,D0009' }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(
            [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
          )
        }
      })
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.offlineReminder.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - paginationHeight - 10
    },
    vehOfflineReminder () {
      getOfflineRemind(this.form).then((res) => {
        if (res) {
          this.tableData = res.data.list
          this.total = res.data.total
          getTablePlaceArr(res.data.list, 'longitude', 'latitude', this)
        }
      })
    },

    handleCurrentChange (v) {
      this.form.currentPage = v
      this.vehOfflineReminder()
    },

    handleSizeChange (v) {
      this.form.pageSize = v
      this.form.currentPage = 1
      this.vehOfflineReminder()
    }
  },
  filters: {
    filterOffline (item) {
      if (item) {
        return '是'
      } else {
        return '否'
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>
<style lang="scss" scoped>
.offline-remind {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
/deep/.el-table thead th.is-leaf {
  line-height: 36px;
}
</style>
