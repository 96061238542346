<template>
  <el-container
    class="realVideo"
    :style="cardWidth[this.$store.state.vehicle.screenCoefficient]"
  >
    <div
      class="box-card"
      :style="cardWidth[this.$store.state.vehicle.screenCoefficient]"
    >
      <div id="fullscreen" class="card-body">
        <videoList
          ref="flvPlayModule"
          :buttonValue="buttonValue"
          @handleCloseVideo="handleCloseVideo"
          @playError="againFetch"
          @currentPlayData="currentPlayData"
          @issueAndrePlay="issueAndrePlay"
          @playEorror="playEorror"
        ></videoList>
      </div>
      <div slot="header" class="clearfix">
        <div class="buttons">
          <el-button
            size="small"
            type="text"
            @click="setPlayerNum(item.value, true)"
            v-for="item in buttons"
            :key="item.key"
            :sort="item.value == buttonValue ? 'primary' : 'grey'"
            :title="`${item.value}画`"
            ><i class="iconfont" :class="item.key"></i
          ></el-button>
        </div>
        <div class="operationBtn">
          <el-select
            v-if="$store.state.user.hdVideo == 1"
            v-model="isHD"
            :disabled="HDSwitchingStatus"
            @change="HDSwitching"
            placeholder="请选择"
            cellclassname="HDSelect"
          >
            <el-option label="标清" value="标清"> </el-option>
            <el-option label="高清" value="高清"> </el-option>
          </el-select>
          <el-button
            style="margin-left: 15px"
            type="text"
            size="small"
            sort="grey"
            title="停止"
            @click="stopAllVideo(false)"
            ><i class="iconfont its_bianzu24"></i
          ></el-button>
          <el-button
            size="small"
            type="text"
            sort="grey"
            title="全屏"
            @click="handleFullScreen"
            ><i class="iconfont its_quanping"></i
          ></el-button>
          <el-button @click="exit()" size="small" type="text" sort="danger"
            ><i class="iconfont its_tuichu1"></i>退出</el-button
          >
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import baseUrl from "@/api/static/config";

import {
  closeVehicleVideo,
  checkVideoStreams,
  checkVideoStreamsLinuxNew,
  addSpotCheck
} from "@/api/lib/refreshAlarm";

import {
  clientVideoError,
} from "@/api/lib/api.js";

import videoList from "./videoListjessibucaWs.vue";

export default {
  name: "realVideo",

  components: {
    videoList
  },
  props: {
    id: {
      type: [String, Number]
    },
    videoStyle: {
      type: String
    }
  },
  data () {
    return {
      params: {}, // 传到视频界面参数
      buttons: [
        { key: "its_1hua", value: 1 },
        { key: "its_4hua", value: 4 },
        { key: "its_6hua", value: 6 },
        { key: "its_9hua", value: 9 },
        { key: "its_16hua", value: 16 }
      ],
      fullscreen: false, // 全屏
      timer_time: null, // 播放定时器
      isHD: "标清", // 是否播放高清视频
      buttonValue: 0,
      playVideoObj: new Map(), // 存放视频播放
      cardWidth: {
        1.3: "width:36vw;",
        1.15: "width:45vw;",
        1: "width:50vw;"
      }, // 整个面板的宽度
      timeExit: null,
      videoTimer: null,
      sendVideoObj: new Map(), // 下发指令的终端（等待回应15s，超过时间停止播放）
      switchHDProblemMap: new Map(), // 控件提示流断开的集合
      switchHDMap: new Map(), // 高清标清切换的obj
      HDSwitchingStatus: false, // 高标清正在切换
      HDSwitchingTimer: null,
      loading: null, // 高标清切换loading
      stopVideoStatus: false, // 正在关闭流
      videoOrderSrc: baseUrl.videoPortList_wsFlv, // 下发指令端口
      videoPortSrc: null, // 判断状态端口
      playVideoObjUrl: [], // 存放视频播放地址
      rePlayTimer: null, //异常断开检测是否需要重新下发指令
      currentPlayDataList: [],//当前正在播放的视频
      tipsList: [],//视频异常提示信息缓存数据
    };
  },
  created () { },
  mounted: function () {
    /** 定时查看是否有播放异常提示 */
    this.allErrortips();
    /** 定时获取流是否可播放 */
    this.getVideoInterval();
    /** 定时获取下发指令的返回状态 */
    this.getStatusInterval();
    this.isHD = "标清";
    this.videoPortSrc = {
      10085: "4022",
      10086: "4023",
      10087: "4024",
      10088: "4025"
    };
    this.$nextTick(function () {
      this.setEventListener();
    });
  },

  methods: {
    /** 清除播放,定时器,监听事件 */
    clear () {
      this.stopAllVideo();
      clearInterval(this.timer_time);
      clearInterval(this.timeExit);
      clearInterval(this.videoTimer);
      clearInterval(this.HDSwitchingTimer);
      clearInterval(this.rePlayTimer);
      clearInterval(this.errortipsTimer);
      this.rePlayTimer = null;
      this.$yhsdp.RemoveRefreshCallBack(
        this.$RefreshTypeEnum.GPS_RealVideo,
        this.getGPS_RealVideoResult
      );
    },
    /** 切换高清/标清 */
    HDSwitching () {
      if (this.playVideoObj.size === 0) return;
      clearInterval(this.timeExit);
      clearInterval(this.videoTimer);
      this.switchHDProblemMap.clear();
      const loading = this.$loading({
        lock: true,
        text: "正在切换中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.HDSwitchingStatus = true;
      this.loading = loading;
      let params = [];
      let channels = []; // 流的存在检查
      this.playVideoObj.forEach((item, key) => {
        /** 高清/标清map */
        this.switchHDMap.set(key, item);
        /** 关闭视频 */
        this.stopParticularVideo(key);
        /** 关闭流 */
        this.$yhsdp.GBRealVideoStreamControlAll(item);
        params.push(item);
        channels.push(key);
      });
      let startTime = new Date();
      this.getStatusInterval();
      this.getVideoInterval();
      this.checkHDVideoStreams(channels, params, startTime);
    },
    /** 获取高清标清切换流断开的状态 */
    checkHDVideoStreams (channels, params, startTime) {
      let self = this;
      /** 等待流断开 */
      self.HDSwitchingTimer = setInterval(() => {
        let timeDiff = new Date().getTime() - startTime.getTime();
        if (timeDiff >= 15000) {
          self.loading.close();
          self.$message.warning("等待响应时间过长，请稍后再试");
          self.HDSwitchingStatus = false;
          self.clear();
          return;
        }
        if (self.playVideoObj.size === 0) return;
        //checkVideoStreams
        checkVideoStreamsLinuxNew({ channels: channels }).then(response => {
          if (response && response.data.noPush.length === channels.length) {
            clearInterval(self.HDSwitchingTimer);
            params.forEach((item, index) => {
              /** 重新下发指令 新增playVideoObj map */
              self.$yhsdp.GBRealVideoStreamReq(item, self.isHD);
              self.sendVideoObj.set(channels[index], {
                date: new Date(),
                number: 1
              });
            });
            self.$refs.flvPlayModule.playVideo(params);
            self.loading.close();
            self.HDSwitchingStatus = false;
          }
        });
      }, 1500);
    },
    /** 全屏事件 */
    handleFullScreen () {
      this.$refs.flvPlayModule.holeVideoFull();
    },
    currentPlayData (val) {
      this.currentPlayDataList = val;
    },
    /** 切换播放画面 */
    //只有点击的时候才会触发
    setPlayerNum (index, flag) {
      /** 点击切换播放画面 */
      if (flag) {
        if (this.playVideoObj.size === 0) {
          this.buttonValue = index;
          return;
        }
        let bigToSmall = false;//是否是多切少
        bigToSmall = this.buttonValue > index;
        console.log("寻找正在播放的视频数据playVideoObj：", this.playVideoObj);

        let currentPlayList = this.currentPlayDataList.filter(item => item);
        // console.log("++++++++++++正在播放的视频currentPlayDataList：",currentPlayList);
        this.buttonValue = index;
        if (bigToSmall) {
          this.$refs.flvPlayModule.closePlayerNum(index);
        } else {
          /** 少切多，重新播放 */
          let params = [];
          //需要添加视频的长度
          let maxLen = index;
          if (currentPlayList.length) maxLen = index - currentPlayList.length;
          this.playVideoObj.forEach((value, key) => {
            //过滤掉正在播放的视频操作
            //如果currentPlayDataList有了则不添加进去
            //利用正在播放的以及按钮长度添加数据
            if (!this.currentPlayDataList.includes(key)) {
              //去重
              if (!params.includes(`${value.device}_channel_${value.channel}`) && maxLen > params.length) {
                params.push(`${value.device}_channel_${value.channel}`);
              }
            }
            //过滤掉正在播放的视频，添加剩余窗口数的数量
            // console.log("blooen",this.currentPlayDataList.includes(key));
            // params.push(`${value.device}_channel_${value.channel}`);
          });
          console.log("少切多画面：", params);
          if (params.length) this.checkPlayerExit({ channels: params });
        }
      }
    },
    /** 监听实时视频是否下发指令成功   成功之后才调用视频的api播放 */
    setEventListener () {
      this.$yhsdp.AddRefreshCallBack(
        this.$RefreshTypeEnum.GPS_RealVideo,
        this.getGPS_RealVideoResult
      );
    },

    /** 下发指令成功|失败    */
    getGPS_RealVideoResult (data) {
      let str = data.flag ? "成功" : "失败";
      // console.log('》》》》》》》》',
      //   `"下发指令${str}"`,
      //   `${data.data.device}_channel_${data.data.channel}`
      // )
    },
    // TODO----

    immediately () {
      return
      console.log("this.sendVideoObj", this.sendVideoObj);
      let self = this;
      let params = [];
      this.sendVideoObj.forEach((item, key) => {
        params.push(key);
      });
      checkVideoStreamsLinuxNew({ channels: params }).then(async res => {
        let { localPush = [], noPush = [], push = [] } = res.data;
        let list = push?.concat(localPush);
        if (list.length) {
          list.forEach(temp => {
            self.sendVideoObj.delete(temp.split(":")[0]);//如果在推流，就去掉下发数据的对应指令数据
          });
        }
        if (noPush.length) {
          let innerArr = [];
          await noPush.forEach(temp => {
            let key = temp.split(":")[0];
            self.$yhsdp.GBRealVideoStreamReq(
              self.playVideoObj.get(key),
              self.isHD
            );
            // console.log('检测下发指令的key是否带有端口号1',key)
            //未推流的，缓存在下发数据Map里
            /**
             * 这里已经是异常播放机制，避免多次下发，计数
             */
            self.sendVideoObj.set(key, {
              date: new Date()
              // number: value.number++
            });

          });
        }
      });
    },

    /** 视频异常提示确认弹窗 */
    playEorror (urlKey, i, error, plate) {
      //每隔三次调接口--终端问题      
      let text;
      const data = {
        terminal: urlKey.split('_')[0],
        channel: urlKey.split('_')[2],
        errorType: error
      };
      if (error != 'rangeError') {
        clientVideoError(data).then();
      }
      if (error === 'terminalError') {

      } else if (error === 'rangeError') {
      } else if (error === 'websocketError' || error === 'videoServiceError') {
        text = `网络异常，检测本地网络是否正常，稍后重试`;
        if (this.remindNotify) {
          this.remindNotify.close();
          this.remindNotify = null;
        };
        this.remindNotify = this.$message({
          type: 'error',
          message: text,
          duration: 10 * 1000
        });
        return;
      }
      const createTime = new Date().getTime();
      //以存入的第一个异常参数的创建时间为起点
      const singleData = {
        time: createTime,
        channel: urlKey.split('_')[2],
        errorType: error,
        terminal:plate.split('_')[0],//修改终端号为展示的车牌号
      }
      this.pushTipsData(singleData);      
    },
    allErrortips () {
      this.errortipsTimer && clearInterval(this.errortipsTimer);
      this.errortipsTimer = setInterval(() => {
        if (this.tipsList && this.tipsList.length) {
          let errorMap = new Map();
          this.tipsList.forEach(item => {
            //如果map中已有对应错误，找出来
            if (errorMap.has(item.errorType)) {
              const index = errorMap.get(item.errorType).findIndex(i => item.terminal === i.terminal);
              if(index > -1){
                errorMap.get(item.errorType)[index].channel = errorMap.get(item.errorType)[index].channel + ',' + item.channel;
              }else{
                errorMap.get(item.errorType).push({ terminal:item.terminal, channel:item.channel, errorType:item.errorType });
              }
            } else {
              errorMap.set(item.errorType, [{ terminal:item.terminal, channel:item.channel, errorType:item.errorType }]);
            }
          });
          //最后循环map，按照不同错误不同车提示用户
          errorMap.forEach((value, key) => {
            let errorText = "";
            value.forEach(({ terminal, channel }) => {
              errorText = `${terminal}车${channel}通道,`;
              if (key === 'terminalError') {
                errorText += `视频流异常，请检查设备情况`;
              } else if (key === 'rangeError') {
                errorText += `视频监控串流，请刷新页面重新观看`;
              }
              this.$message({
                type: 'error',
                message: errorText,
                duration: 6 * 1000
              });
            })
          });
          //提示完了就清空数据
          this.$nextTick(()=>{
            this.tipsList = [];            
          })
        } else {
          return
        }

      }, 15 * 1000);

    },
    pushTipsData (obj) {
      //初步设置10秒内的异常为一组提示
      if (this.tipsList && this.tipsList.length) {
        if (obj.time - this.tipsList[0].time <= 10 * 1000) {
          this.tipsList.push(obj);
        } else {
          this.tipsList = [];
        }
      } else {
        this.tipsList.push(obj);
      }
    },
    //播放异常-拿到数据重新下发指令推流-replay
    issueAndrePlay (value, i) {
      console.log("异常播放key:", i, value);
      const innerArr = [];
      if (this.playVideoObj.has(value)) {
        let data = this.playVideoObj.get(value);
        data.port = this.videoOrderSrc;
        innerArr.push({
          ...data,
        });
        this.$yhsdp.GBRealVideoStreamReq(data, this.isHD)
        this.sendVideoObj.set(value, {
          date: new Date(),
          number: 1
        })
      }
      // setTimeout(()=>{
      //   innerArr.length && this.$refs.flvPlayModule.playVideo(innerArr,i);
      // },1000 * 0.5)
    },
    //重新播放异常视频
    rePlayVideo () {
      return
      const self = this;
      if (self.rePlayTimer) {
        return;
      }
      this.rePlayTimer = setInterval(() => {
        console.log("self.playVideoObj", self.playVideoObj);
        if (self.playVideoObj.size === 0) return;//非空处理，没有需要播放的视频
        console.log('=====>>>检测是否有异常关闭的，重新播放', self.playVideoObj, '<<<=====')
        let params = [];
        this.playVideoObj.forEach((item, key) => {
          params.push(key);//存放的视频数据
        });
        /**
         * 检查点开的视频数据-推流状态
         * 请求后端接口-判断是否有流异常-没有推了-对没有推的流做异常机制(下发指令，reload)
         * 在推流---不做处理
         * 没有推流：下发指令-拿到地址播放
         * 本地在看的流地址：直接用地址播放，不用下发指令
         */
        checkVideoStreamsLinuxNew({ channels: params }).then(async res => {
          if (res) {
            let noPush = res.data.noPush;
            if (noPush.length > 0) {
              let innerArr = [];
              await noPush.forEach(item => {
                let key = item.split(":")[0];
                /* 可以直接下发指令的  获取可用的url */
                if (self.playVideoObj.has(key)) {
                  /** 下发指令 */
                  let data = self.playVideoObj.get(key);
                  data.port = this.videoOrderSrc;//9505
                  self.playVideoObj.set(key, {
                    ...data
                  });
                  self.$yhsdp.GBRealVideoStreamReq(data, self.isHD);
                  // console.log('检测下发指令的key是否带有端口号2',key)
                  self.sendVideoObj.set(key, {
                    date: new Date(),
                    number: 1
                  });
                  innerArr.push({
                    ...data
                  });
                }
              });
              innerArr.length && this.$refs.flvPlayModule.playVideo(innerArr);
            }
          }
        });
      }, 20 * 1000);//12
    },

    //检测到异常，先关闭该视频，然后重新下发指令拉流
    /**
     * param 设备编号+通道号
     */
    async againFetch (key) {
      let { device, channel, plate, vehId } = { ...this.playVideoObj.get(key) };

      this.sendVideoObj.set(key, {
        date: new Date(),
        number: 1
      });
      await this.handleToPlayVideo({ device, plate, vehId }, [channel], "open");

    },

    // TODO----
    /** 获取下发指令的状态 */
    getStatusInterval () {
      let self = this;
      // let carName = new Set();
      let step = 10;//4

      self.timeExit = setInterval(() => {
        if (self.sendVideoObj.size === 0) {
          //检测是否有异常关闭的，重新播放
          /**
           * 请求后端接口-判断是否有流异常-没有推了-对没有推的流做异常机制(下发指令，reload)
           */
          self.rePlayVideo();
          return;
        }
        self.rePlayTimer && clearInterval(self.rePlayTimer);
        self.rePlayTimer = null;
        this.immediately();
      }, step * 1000);
    },
    //显示界面
    showVideo () {
      this.buttonValue = 4;
    },
    /** 
     * 点击播放视频
     * 组织树上点击触发的事件
    */
    handleToPlayVideo (params, channel, flag, isInitHD) {
      if (isInitHD) this.initHD();
      let channelArr = [];
      this.params = { ...params };
      channel.forEach(item => {
        channelArr.push(`${this.params.device}_channel_${item}`);
      });
      this.params.channel = channel;
      this.params.channelObj = {
        channels: channelArr
      };
      this.params.playTime = new Date();
      switch (flag) {
        case "open":
          this.open();//播放
          break;
        case "close":
          this.close();//停止
          break;
        default:
          break;
      }
    },
    /** 播放 */
    open () {
      this.setVideoMap();//存储播放对象---何时存？何时释放    ----this.playVideoObj  
      this.addSpotCheck();//调用添加抽查信息----何用？
      this.changePlayerNum(); // 设置新的窗口数----buttonValue
      this.checkPlayerExit(this.params.channelObj);
      if (this.switchHDProblemMap.has(this.params.device)) {
        this.switchHDProblemMap.delete(this.params.device);
      }
    },
    //添加抽查信息
    addSpotCheck () {
      let system = sessionStorage.getItem("system");
      let deviceType = system === "2" ? "0" : system;
      addSpotCheck({
        type: 3,
        ids: this.params.vehId,
        system,
        deviceType
      }).then();
    },

    /** 存储播放对象 */
    setVideoMap () {
      this.params.channel.forEach((item, key) => {
        let value = { ...this.params };
        delete value.channelObj;
        value.channel = item;//通道号1、2、3、4      
        this.playVideoObj.set(`${this.params.device}_channel_${item}`, value);
      });
      console.log('-----存储播放对象-----', this.playVideoObj)
    },
    /** 获取可用的端口地址 4路服务负载均衡 */
    checkPlayUrl (arr, port) {
      //arr ---- ["4022","4023","4024"]
      let videoOrderSrc = { ...this.videoOrderSrc };
      if (!arr.length && !port) {
        return Object.keys(videoOrderSrc)[0];
      }
      let res = {};

      // 遍历数组
      arr.forEach(item => {
        if (item.length) {
          res[item] ? (res[item] += 1) : (res[item] = 1);
          //res = {4022:1}
        }
      });
      /** 已存在的流是否可以使用该端口播放 */
      //port - 4022 4023
      if (port && (res[port] < 6 || typeof res[port] !== "number")) {
        return port;
      }
      /** 遍历 res  出现次数最少的 */
      let minName = Object.keys(res)[0];
      let min = res[Object.keys(res)[0]];
      for (let r in videoOrderSrc) {
        if (typeof res[r] === "number") {
          if (res[r] < min) {
            min = res[r];
            minName = r;
          }
        } else {
          minName = r;
          break;
        }
      }
      return minName;
    },

    /** 确认播放通道是否打开xxxx */
    checkPlayerExit (params) {
      const innerArr = [];
      params.channels.forEach(item => {
        if (this.playVideoObj.has(item)) {
          let data = this.playVideoObj.get(item);
          data.port = this.videoOrderSrc;//9505
          this.playVideoObj.set(item, {
            ...data
          });
          innerArr.push({
            ...data,
          });
          this.$yhsdp.GBRealVideoStreamReq(data, this.isHD)
          this.sendVideoObj.set(item, {
            date: new Date(),
            number: 1
          })
        }
      })
      innerArr.length && this.$refs.flvPlayModule.playVideo(innerArr);
      return
      //      checkVideoStreamsLinuxNew 检查流状态，后端只返回了观看人数是否大于0，有人看不需要下发指令，直接看
      checkVideoStreamsLinuxNew(params).then(res => {
        if (res) {
          let noPush = res.data.noPush;//查询的数据-没有在推的集合---下发指令
          let push = res.data.push.concat(res.data.localPush);//查询的数据里---正在推流的-----拿到推的端口指定播放地址---直接播放的
          if (noPush.length > 0) {
            noPush.forEach((item) => {
              if (this.playVideoObj.has(item)) {
                let data = this.playVideoObj.get(item);
                data.port = this.videoOrderSrc;//9505
                this.playVideoObj.set(item, {
                  ...data
                });
                innerArr.push({
                  ...data,
                });
                this.$yhsdp.GBRealVideoStreamReq(data, this.isHD)
                this.sendVideoObj.set(item, {
                  date: new Date(),
                  number: 1
                })
              }
            })
            console.log('this.sendVideoObj.size:', this.sendVideoObj.size)
          }
          if (push.length > 0) {
            /** 有流正在播放的 直接播放 */
            push.forEach((value) => {
              let key = value.split(":")[0];
              let data = this.playVideoObj.get(key);
              data.port = this.videoOrderSrc;//9505
              this.playVideoObj.set(value, {
                ...data,
              });
              innerArr.push({
                ...data,
              });
            })
          }
          innerArr.length && this.$refs.flvPlayModule.playVideo(innerArr);
        }
      })
    },

    /** 发送到iframe 播放界面 */
    openIframe () {
      let params = [];
      this.playVideoObj.forEach((value, key) => {
        params.push(value);
      });
      this.$refs.flvPlayModule.playVideo(params);
    },

    /** 根据当前播放数量 设置对应的播放窗口 */
    changePlayerNum () {
      let index = this.buttons.find(item => {
        return item.value >= this.playVideoObj.size;
      });
      this.buttonValue = index.value;
    },

    /** 点击暂停 */
    close () {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.delVideoMap(); // 关闭对应的窗口;

      this.openIframe(); // 播放之前的窗口
      setTimeout(() => {
        loading.close();
      }, 2000);
    },
    /** 找到应删除对象 删除播放对象 */
    delVideoMap () {
      let params = [];
      this.params.channel.forEach(item => {
        const device = `${this.params.device}_channel_${item}`;
        params.push(device);
        if (this.playVideoObj.has(device)) {
          this.stopParticularVideo(device);
          const obj = this.playVideoObj.get(device);
          this.playVideoObjUrl.splice(
            this.playVideoObjUrl.findIndex(value => value === obj.url),
            1
          );
          this.playVideoObj.delete(device);
          this.$emit(
            "closePlay",
            `${obj.vehId}`,
            `${obj.plate}摄像头${obj.channel}`
          );
        }
        if (this.sendVideoObj.has(device)) this.sendVideoObj.delete(device);
      });
      /** 调用接口 通知后台关流 出租车 */
      // closeVehicleVideo({ channels: params }).then((res) => {})
    },
    /** 暂停播放  对应icon点击的 */
    stopParticularVideo (params) {
      this.$refs.flvPlayModule.stopParticularVideo(params);
    },
    /** 停止全部 */
    stopAllVideo (flag) {
      let params = [];
      this.playVideoObj.forEach((value, key) => {
        params.push(key);
      });
      this.playVideoObj.clear();
      this.sendVideoObj.clear();
      this.switchHDMap.clear();
      this.switchHDProblemMap.clear();
      this.playVideoObjUrl = [];

      /** tree页面点击历史视频关闭实时视频 */
      if (!flag) this.$emit("stopAllVideo");
      this.$refs.flvPlayModule.flv_destroyAll();
      /** 调用接口 通知后台关流 */
      // closeVehicleVideo({ channels: params }).then((res) => {})
    },
    /** 退出视频界面 */
    exit () {
      let params = [];
      this.playVideoObj.forEach((value, key) => {
        params.push(key);
      });
      this.playVideoObj.clear();
      this.sendVideoObj.clear();
      this.switchHDMap.clear();
      this.switchHDProblemMap.clear();
      this.playVideoObjUrl = [];

      this.$emit("exit");
      this.$refs.flvPlayModule.flv_destroyAll();
      /** 调用接口 通知后台关流 */
      // closeVehicleVideo({ channels: params }).then((res) => {})
    },
    /* 点击页面上的关闭 */
    handleCloseVideo (params) {
      /** 调用接口 通知后台关流 */
      // closeVehicleVideo({ channels: [params] }).then((res) => {})
      /** 一般停止 */
      let obj = this.playVideoObj.get(params);
      this.playVideoObjUrl.splice(
        this.playVideoObjUrl.findIndex(value => value === obj.url),
        1
      );
      // console.log("csplay===========>>>>", 2);

      /**这里使用map值 */
      this.$emit(
        "closePlay",
        `${obj.vehId}`,
        `${obj.plate}摄像头${obj.channel}`
      );
      this.playVideoObj.delete(params);
      this.sendVideoObj.delete(params);
    },
    /** 初始化 标清状态 */
    initHD () {
      if (this.playVideoObj.size === 0) this.isHD = "标清";
    },
    /** 定时获取流服务是否可用  为节省内存定时2分钟请求 */
    getVideoInterval () {
      //TODO---
      this.rePlayVideo();
      
    }
  },
  /** 页面销毁 清除监听 */
  beforeDestroy () {
    this.clear();
  }
};
</script>

<style lang="scss" scoped>
.realVideo {
  position: absolute;
  border: 1px;
  top: 70px;
  right: 16px;
  z-index: 2000;
  background: #fff;
  .box-card {
    height: 45vh;
    .card-body {
      padding-top: 2px;
      width: 100%;
      height: 40vh;
    }
    .clearfix {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4vh;
      position: relative;
      border-top: 1px solid #ccc;
      padding: 0 20px;
      .operationBtn {
        margin-left: 16px;
        height: 32px;
      }
      span {
        font-size: 16px;
      }
      i {
        font-size: 16px;
      }
    }
  }
}
</style>
