import { render, staticRenderFns } from "./offlineReminder.vue?vue&type=template&id=15c3f936&scoped=true&"
import script from "./offlineReminder.vue?vue&type=script&lang=js&"
export * from "./offlineReminder.vue?vue&type=script&lang=js&"
import style0 from "./offlineReminder.vue?vue&type=style&index=0&id=15c3f936&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c3f936",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/codingci/tools/jenkins_home/workspace/1551980-cci-46937945-950616/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15c3f936')) {
      api.createRecord('15c3f936', component.options)
    } else {
      api.reload('15c3f936', component.options)
    }
    module.hot.accept("./offlineReminder.vue?vue&type=template&id=15c3f936&scoped=true&", function () {
      api.rerender('15c3f936', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/monitor/monitoring/table/components/offlineReminder.vue"
export default component.exports